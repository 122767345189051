import React from "react";
import {
  compose,
  renderNothing,
  renderComponent,
  branch,
  lifecycle,
  withState
} from "recompose";

import OpeningTimes from "./openingTimes";

const HeaderInfoBox = ({ openHours }) => (
  <div class="header__info-box">
    <OpeningTimes openHours={openHours} />
    <p class="header__info-box-icons">
      <a class="black-color u-margin-right-small" href="tel:+491716954278">
        <i class="fas fa-phone-alt"></i>
      </a>
      <a
        class="black-color u-margin-right-small"
        href="https://wa.me/491716954278"
      >
        <i class="fab fa-whatsapp "></i>
      </a>
      <a
        class="black-color u-margin-right-small"
        href="#anchor-kontaktformular"
      >
        <i class="far fa-envelope-open"></i>
      </a>
      <a
        class="black-color u-margin-right-small"
        href="https://www.facebook.com/Blumen.Theo.Klemmer/"
      >
        <i class="fab fa-facebook-f"></i>
      </a>
      <a
        class="black-color u-margin-right-small"
        href="https://www.google.com/maps/place/Blumen+Theo+Klemmer/@50.7400858,7.0460621,17z/data=!3m1!4b1!4m5!3m4!1s0x47bf1e2104832941:0xe170b49aa01d3040!8m2!3d50.7400858!4d7.0482508"
      >
        <i class="fas fa-map-marker-alt"></i>
      </a>
    </p>
  </div>
);

export default compose(
  lifecycle({
    componentDidUpdate() {}
  })
)(HeaderInfoBox);
