import React from "react";
import axios from "axios";
import { compose, withState, lifecycle, withHandlers } from "recompose";
import { set } from "lodash";
import RegularOpenHoursBlock from "./openHours";
import HeaderInfoBox from "./headerInfoBox";
import { toast, ToastContainer } from "react-toastify";
import ImageGallery from "react-image-gallery";

const Main = ({ setTime, sendMail, regularClosingTimes, tempClosingTimes }) => (
  <React.Fragment>
    <ToastContainer />
    <header class="header">
      <HeaderInfoBox
        openHours={{
          tempClosingTimes: tempClosingTimes,
          regularClosingTimes: regularClosingTimes
        }}
      />
      <div class="header__text-box">
        <img
          srcset="img/full-logo-mini.png 300w, img/full-logo-mini.png 1000w"
          sizes="(max-width: 56.25em) 100vw, (max-width: 37.5em) 30vw, 1000px"
          alt="header-logo"
        />

        <h1 class="heading-primary">
          <span class="heading-primary--main">
            Blumen für Bonn und die Region
          </span>
        </h1>
      </div>
    </header>

    <main>
      <section class="section-about section-about-padding">
        <div class="u-center-text u-margin-bottom-big">
          <h2 class="heading-secondary">Über uns</h2>
        </div>

        <div class="row">
          <div class="col-1-of-2">
            <h3 class="heading-tertiary u-margin-bottom-small">
              Seit über 70 Jahren ist unser Geschäft ein Familienbetrieb
            </h3>
            <p class="paragraph">
              Es arbeitet bereits die 3. Generation mit. Aus dem ursprünglichen
              Produktionsbetrieb, mit überwiegend einheimischen Blumen, ist seit
              20 Jahren ein Floristikgeschäft mit Blumen auch aus aller Welt
              geworden.
            </p>

            <h3 class="heading-tertiary u-margin-bottom-small">
              Sie wollen uns kontaktieren?
            </h3>
            <p class="paragraph">
              Schreiben Sie uns eine Mail über das{" "}
              <a class="btn-text" href="#anchor-kontaktformular">
                Kontaktformular{" "}
              </a>
              , direkt an{" "}
              <a
                class="btn-text"
                href="mailto:inxfo@blumxen-klemmxer.de"
                onmouseover="this.href=this.href.replace(/x/g,'');"
              >
                info@blumen-klemmer.de
              </a>
              oder per
              <a href="https://wa.me/491716954278" class="btn-text">
                WhatsApp
              </a>
              .
            </p>

            <p class="paragraph">
              Gerne können Sie uns auch anrufen : <br />
              Mobil:
              <a class="btn-text" href="tel:+491716954278">
                0171/695 42 78
              </a>
              <br />
              Festnetz:
              <a class="btn-text" href="tel:+49228669094">
                0228/66 90 94
              </a>
            </p>
          </div>
          <div class="col-1-of-2">
            <div class="composition">
              <img
                srcset="img/basket.jpg 300w, img/basket.jpg 1000w"
                sizes="(max-width: 56.25em) 20vw, (max-width: 37.5em) 30vw, 300px"
                alt="Photo 1"
                class="composition__photo composition__photo--p1"
                src="img/basket.jpg"
              />
            </div>
          </div>
        </div>
      </section>

      <section class="section-features">
        <div class="row">
          <div class="col-1-of-4">
            <div class="feature-box">
              <i class="feature-box__icon fas fa-seedling"></i>
              <h3 class="heading-tertiary u-margin-bottom-small">
                Frische Blumen für Bonn und die Region!
              </h3>
              <p class="feature-box__text">
                Floristikfachgeschäft mit Blumen aus aller Welt.
              </p>
            </div>
          </div>

          <div class="col-1-of-4">
            <div class="feature-box">
              <i class="feature-box__icon fas fa-clock"></i>
              <h3 class="heading-tertiary u-margin-bottom-small">
                Öffnungszeiten
              </h3>
              <p class="feature-box__text">
                <RegularOpenHoursBlock openHours={regularClosingTimes} />
              </p>
            </div>
          </div>

          <div class="col-1-of-4">
            <div class="feature-box">
              <i class="feature-box__icon fas fa-map-marker-alt"></i>
              <h3 class="heading-tertiary u-margin-bottom-small">Adresse</h3>
              <p class="feature-box__text">
                Blumen Theo Klemmer <br />
                Justus-von-Liebig Straße 40
                <br />
                53121 Bonn-Dransdorf
              </p>
            </div>
          </div>

          <div class="col-1-of-4">
            <div class="feature-box">
              <i class="feature-box__icon fas fa-truck"></i>
              <h3 class="heading-tertiary u-margin-bottom-small">
                Blumen-Liefer-Service
              </h3>
              <p class="feature-box__text">
                Ab einem Warenwert von 20,- Euro liefern wir im Raum Bonn frei
                Haus.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section class="section-tours" id="section-tours">
        <div class="u-center-text u-margin-bottom-big">
          <h2 class="heading-secondary">Unsere Angebote</h2>
        </div>

        <div class="row">
          <div class="col-1-of-3">
            <div class="card" id="#anchor-hochzeit">
              <div class="card__side card__side--front">
                <div class="card__picture card__picture--2">&nbsp;</div>
                <h4 class="card__heading">
                  <span class="card__heading-span card__heading-span--2">
                    Hochzeiten
                  </span>
                </h4>
                <div class="card__details">
                  <ul>
                    <li>
                      Gestalten sie den schönsten Tag im Leben mit Blumen
                      jeglicher Art
                    </li>
                    <li>
                      Wir fertigen Ihnen Ihren persönlichen Hochzeitsstrauß
                    </li>
                    <li>
                      Gerne zeigen wir Ihnen auch Beispiele von Autogestecken
                      und Tischschmuck!
                    </li>
                  </ul>
                </div>
              </div>
              <div class="card__side card__side--back card__side--back-2">
                <div class="card__cta">
                  <ImageGallery
                    items={[
                      {
                        original: "img/wedding/hochzeit1.jpg"
                      },
                      {
                        original: "img/wedding/hochzeit2.jpg"
                      },
                      {
                        original: "img/wedding/hochzeit3.jpg"
                      },
                      {
                        original: "img/wedding/hochzeit4.jpg"
                      },
                      {
                        original: "img/wedding/hochzeit5.jpg"
                      }
                    ]}
                    showThumbnails={false}
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="col-1-of-3" id="anchor-trauerfall">
            <div class="card">
              <div class="card__side card__side--front">
                <div class="card__picture card__picture--1">&nbsp;</div>
                <h4 class="card__heading">
                  <span class="card__heading-span card__heading-span--1">
                    Trauerfall
                  </span>
                </h4>
                <div class="card__details">
                  <ul>
                    <li>
                      Trauerkränze und Trauergestecke fertigen wir in
                      verschiedenen Variationen.
                    </li>
                    <li>
                      Wir helfen Ihnen die Trauer-Accessoires würdig, liebevoll
                      und individuell für Sie zu gestalten.
                    </li>
                  </ul>
                </div>
              </div>
              <div class="card__side card__side--back card__side--back-1">
                <div class="card__cta">
                  <ImageGallery
                    items={[
                      {
                        original: "img/funeral/trauer1.jpg"
                      },
                      {
                        original: "img/funeral/trauer2.jpg"
                      },
                      {
                        original: "img/funeral/trauer3.jpg"
                      },
                      {
                        original: "img/funeral/trauer4.jpg"
                      }
                    ]}
                    showThumbnails={false}
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="col-1-of-3">
            <div class="card" id="anchor-pflanzsaison">
              <div class="card__side card__side--front">
                <div class="card__picture card__picture--3">&nbsp;</div>
                <h4 class="card__heading">
                  <span class="card__heading-span card__heading-span--3">
                    Pflanzensaison
                  </span>
                </h4>
                <div class="card__details">
                  <ul>
                    <li>
                      Jedes Jahr finden Sie bei uns im Frühjahr und im Sommer
                      aktuelle Beet -und Balkonpflanzen
                    </li>
                    <li>
                      Also zögern Sie nicht und holen auch Sie sich den Frühling
                      in Ihr Zuhause.
                    </li>
                  </ul>
                </div>
              </div>
              <div class="card__side card__side--back card__side--back-3">
                <div class="card__cta">
                  <ImageGallery
                    items={[
                      {
                        original: "img/pflanzensaison/saison1.jpg"
                      },
                      {
                        original: "img/pflanzensaison/saison2.jpg"
                      }
                    ]}
                    showThumbnails={false}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="section-book">
        <div class="row">
          <div class="book">
            <div class="book__form">
              <form
                onSubmit={sendMail}
                id="anchor-kontaktformular"
                class="form"
              >
                <div class="u-margin-bottom-medium">
                  <h2 class="heading-secondary">Kontakt</h2>
                </div>

                <div class="form__group">
                  <input
                    type="text"
                    class="form__input"
                    placeholder="Ihr Name"
                    id="name"
                    required
                    onChange={e => setTime(e.target.value, "name")}
                  />
                  <label for="name" class="form__label">
                    Ihr Name
                  </label>
                </div>

                <div class="form__group">
                  <input
                    type="text"
                    class="form__input"
                    placeholder="Telefonnummer (kein Pflichtfeld)"
                    id="phone"
                    onChange={e => setTime(e.target.value, "phone")}
                  />
                  <label for="phone" class="form__label">
                    Telefonnummer (kein Pflichtfeld)
                  </label>
                </div>

                <div class="form__group">
                  <input
                    type="email"
                    class="form__input"
                    placeholder="Email Adresse"
                    id="email"
                    onChange={e => setTime(e.target.value, "email")}
                    required
                  />
                  <label for="email" class="form__label">
                    Email Adresse
                  </label>
                </div>

                <div class="form__group">
                  <textarea
                    id="text"
                    class="form__input"
                    placeholder="Ihre Nachricht an uns"
                    onChange={e => setTime(e.target.value, "message")}
                    rows="5"
                    cols="50"
                  ></textarea>
                  <label for="text" class="form__label">
                    Ihre Nachricht an uns
                  </label>
                </div>

                <div class="form__group">
                  <button type="submit" class="btn btn--green">
                    Email absenden &rarr;
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </main>

    <footer class="footer">
      <div class="row">
        <div class="col-1-of-2">
          <div class="footer__navigation">
            <ul class="footer__list">
              <li class="footer__item">
                <a
                  href="https://www.facebook.com/Blumen.Theo.Klemmer"
                  class="footer__link"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Facebook
                </a>
              </li>
              <li class="footer__item">
                <a
                  href="/datenschutz"
                  class="footer__link"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Datenschutzerklärung
                </a>
              </li>
              <li class="footer__item">
                <a
                  href="impressum"
                  class="footer__link"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Impressum
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-1-of-2">
          <p class="footer__copyright">
            <b>Inhaber:</b>
            <br />
            Theo Klemmer
            <br />
            Justus-von-Liebig Straße 40
            <br />
            53121 Bonn <br />
            Umsatzsteuer-Identifikationsnummer: DE122159106
            <br />
            Zuständige Kammer: Industrie und Handelskammer Bonn
          </p>
        </div>
      </div>
    </footer>

    <div class="popup" id="popup-trauerfall">
      <div class="popup__content">
        {/*       <Gallery
          photos={[
            {
              src: "img/funeral/trauer1.jpg"
            },
            { src: "img/funeral/trauer2.jpg" },
            { src: "img/funeral/trauer3.jpg" },
            { src: "img/funeral/trauer4.jpg" }
          ]}
        /> */}
      </div>
      <a href="#anchor-trauerfall" class="popup__close--outer">
        &nbsp;
      </a>
    </div>

    <div class="popup" id="popup-hochzeit">
      <div class="popup__content">
        {/* <Gallery
          photos={[
            {
              src: "img/wedding/hochzeit1.jpg"
            },
            { src: "img/wedding/hochzeit2.jpg" },
            { src: "img/wedding/hochzeit3.jpg" },
            { src: "img/wedding/hochzeit4.jpg" }
          ]}
        /> */}
        ;
      </div>
      <a href="#anchor-hochzeit" class="popup__close--outer">
        &nbsp;
      </a>
    </div>

    <div class="popup" id="popup-pflanzensaison">
      <div class="popup__content">
        {/* <Gallery
          photos={[
            {
              src: "img/funeral/trauer1.jpg"
            },
            { src: "img/funeral/trauer2.jpg" },
            { src: "img/funeral/trauer3.jpg" },
            { src: "img/funeral/trauer4.jpg" }
          ]}
        /> */}
        ;
      </div>
      <a href="#anchor-pflanzsaison" class="popup__close--outer">
        &nbsp;
      </a>
    </div>
  </React.Fragment>
);

export default compose(
  withState("open", "setOpen", true),
  withState("openHours", "setOpenHours", null),
  withState("regularClosingTimes", "setRegularClosingTimes", null),
  withState("tempClosingTimes", "setTempClosingTimes", null),
  withState("toggleGallery", "setToggleGallery", false),
  withState("emailContent", "setEmailContent", {
    email: "",
    message: "",
    phone: "",
    name: ""
  }),
  withHandlers({
    sendMail: ({ emailContent }) => e => {
      e.preventDefault();
      const d = new Date();
      const datum = d.getDate() + d.getMonth();
      axios
        .post("https://klemmer.firmen-api.com/times/sendmail", {
          email: emailContent.email,
          phone: emailContent.phone,
          name: emailContent.name,
          message: emailContent.message,
          datum: datum
        })
        .then(result => {
          document.getElementById("anchor-kontaktformular").reset();
          toast.info(
            "Danke, die Email wurde abgeschickt, wir werden uns bald bei Ihnen melden.",
            {
              position: toast.POSITION.TOP_CENTER
            }
          );
        })
        .catch(err => {
          toast.error("Fehlgeschlagen, bitte überprüfen Sie Ihre Angaben!", {
            position: toast.POSITION.TOP_CENTER
          });
        });
    },
    setTime: ({ emailContent, setEmailContent }) => (value, path) => {
      set(emailContent, path, value);

      setEmailContent(emailContent);
    }
  }),
  lifecycle({
    componentDidMount() {
      axios
        .get("https://klemmer.firmen-api.com/times/regularTimes")
        .then(result => {
          this.props.setRegularClosingTimes(result.data);
        })
        .catch(err => {});

      axios
        .get("https://klemmer.firmen-api.com/times/tempTimes")
        .then(result => {
          this.props.setTempClosingTimes(result.data);
        })
        .catch(err => {});
    }
  })
)(Main);
