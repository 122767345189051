import React from "react";
import { compose, renderNothing, renderComponent, branch } from "recompose";

const RegularOpenHoursBlock = ({ openHours }) => (
  <React.Fragment>
    Montag – Freitag {openHours.weekdays.open} –{openHours.weekdays.closed}{" "}
    <br />
    Samstag {openHours.saturday.open} –{openHours.saturday.closed} <br />
    Sonntag {openHours.sunday.open} –{openHours.sunday.closed}
  </React.Fragment>
);

export default compose(
  branch(
    ({ openHours }) => openHours,
    renderComponent(RegularOpenHoursBlock),
    renderNothing
  )
)(RegularOpenHoursBlock);
