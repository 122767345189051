import React from "react";
import { compose, lifecycle, withHandlers, withState } from "recompose";
import axios from "axios";

import { toast, ToastContainer } from "react-toastify";

import ChangeZeiten from "./changeZeiten";

const Zeiten = ({
  isAuth,
  loginUser,
  password,
  token,
  setPassword,
  email,
  setEmail
}) => (
  <div>
    <ToastContainer />
    {!isAuth ? (
      <section class="section-book">
        <div class="row">
          <div class="book">
            <div class="book__form">
              <form onSubmit={loginUser}>
                Email:
                <br />
                <input
                  type="text"
                  name="Email"
                  onChange={e => setEmail(e.target.value)}
                  value={email}
                />
                <br />
                Password
                <br />
                <input
                  type="password"
                  name="lastname"
                  onChange={e => setPassword(e.target.value)}
                  value={password}
                />
                <br />
                <br />
                <button class="btn btn--green" type="submit">
                  Einloggen
                </button>
              </form>
            </div>
          </div>
        </div>
      </section>
    ) : (
      <div>
        <ChangeZeiten token={token} />
      </div>
    )}
  </div>
);

export default compose(
  withState("email", "setEmail", ""),
  withState("isAuth", "setIsAuth", false),
  withState("token", "setToken"),
  withState("userId", "setUserId", ""),
  withState("password", "setPassword", ""),

  withHandlers({
    loginUser: ({ password, email, token }) => e => {
      e.preventDefault();

      axios
        .post("https://klemmer.firmen-api.com/auth/login", { email, password })
        .then(resData => {
          localStorage.setItem("token", resData.data.token);
          localStorage.setItem("userId", resData.data.userId);
          const remainingMilliseconds = 60 * 60 * 1000;
          const expiryDate = new Date(
            new Date().getTime() + remainingMilliseconds
          );
          localStorage.setItem("expiryDate", expiryDate.toISOString());
          window.location.reload();
        })
        .catch(err => {
          toast.error("Login fehlgeschlagen!", {
            position: toast.POSITION.TOP_CENTER
          });
        });
    }
  }),
  lifecycle({
    componentDidMount() {
      const token = localStorage.getItem("token");
      const expiryDate = localStorage.getItem("expiryDate");
      if (!token || !expiryDate) {
        return;
      }
      if (new Date(expiryDate) <= new Date()) {
        return;
      }

      const userId = localStorage.getItem("userId");
      const remainingMilliseconds =
        new Date(expiryDate).getTime() - new Date().getTime();
      this.props.setToken(token);
      this.props.setIsAuth(true);
      this.props.setUserId(userId);
    }
  })
)(Zeiten);
