import React from "react";
import { compose } from "recompose";

const Datenschutz = ({ someProp }) => (
  <main>
    <section class="section-about section-about-border">
      <div>
        <div class="u-center-text u-margin-bottom-big">
          <h2 class="heading-secondary">Datenschutzerklärung</h2>
        </div>
        <h2 class="heading-tertiary u-margin-bottom-small">Zusammenfassung</h2>

        <p class="paragraph">
          Ihr Datenschutz ist und sehr wichtig, weshalb die wir keinerlei
          personenbezogene Daten dauerhaft speichern.
        </p>
        <p class="paragraph">
          Wir nutzen weder Social Plugins (z.B Like Buttons, G+ etc.), noch
          tracken wir den Besuch unserer Webseite über Google Analytics oder
          Piwik/Matomo etc.
          <br />
          Bei dem Besuch unserer öffentlichen Seite werden von uns keinerlei
          Cookies gesetzt!
        </p>
        <p class="paragraph">
          Der einzige Zweck unserer Homepage besteht darin, unsere Besucher über
          unsere Produkte und unseren Service zu informieren. Es besteht nicht
          die Möglichkeit sich zu registrieren, noch können Kommentare
          hinterlassen werden.
        </p>
        <p class="paragraph">
          In sofern Sie uns über das per E-Mail (durch Ihr E-Mail Programm)
          kontaktieren, wird diese E-Mail in unserem Mail-Postfach, mit den von
          Ihnen bereit gestellten Informationen, gespeichert.
          <br />
          Die mitgeschickten personenbezogenen Daten werden ausschließlich dazu
          verwendet Ihre Fragen zu beantworten, oder um (falls gewünscht) ein
          Auftragsangebot zu erstellen.
          <br />
          Nach abgeschlossener Kommunikation wird der E-Mail Verlauf gelöscht.
          <br />
          Ohne Ihre explizite Erlaubnis werden keinerlei Informationen an Dritte
          weitergegeben.
        </p>
        <p class="paragraph">
          Um zu vermeiden das personenbezogene Daten durch Dritte erfasst
          werden, hosten wir fast alle nötigen Scripte und Stylesheets über
          unseren Webspace und haben lediglich die &#8222;Google Webfonts
          Library&#8220; (welche Schriftarten bereitstellt) eingebunden.
        </p>
        <p class="paragraph">
          Mit unserem Hosting-Anbieter wurde ein Auftragsverarbeitungs-Vertrag
          geschlossen. Welche Daten für ein sorgfältiges Laden der Homepage
          (serverseitig) nötig sind können Sie im Abschnitt
          &#8222;Serverdaten&#8220; nachlesen.
        </p>
        <h3 class="heading-tertiary u-margin-bottom-small">
          Generelle Datenschutzerklärung:
        </h3>
        <p class="paragraph">
          Die Nutzung unserer Seite ist ohne eine Angabe von personenbezogenen
          Daten möglich. Für die Nutzung einzelner Services unserer Seite können
          sich hierfür abweichende Regelungen ergeben, die in diesem Falle
          nachstehend gesondert erläutert werden.
          <br />
          Ihre personenbezogenen Daten werden von uns nur gemäß den Bestimmungen
          der EU- Datenschutzgrundverordnung (DSGVO) und dem
          Bundesdatenschutzgesetz (BDSG) verarbeitet. Daten sind dann
          personenbezogen, wenn sie eindeutig einer bestimmten natürlichen
          Person zugeordnet werden können. Nachstehende Regelungen informieren
          Sie insoweit über die Art, den Umfang und Zweck der Erhebung, die
          Nutzung und die Verarbeitung von personenbezogenen Daten durch den
          Anbieter:
        </p>
        <p class="paragraph">
          <strong>Theo Klemmer; Justus-von-Liebig Str. 40; 53121 Bonn</strong>
        </p>
        <p class="paragraph">
          <strong>Telefon: 0228768158</strong>
        </p>
        <p class="paragraph">
          <strong>bt-klemmer(ät)gmx.de</strong>
        </p>
        <p class="paragraph">
          Wir weisen darauf hin, dass die internetbasierte Datenübertragung
          Sicherheitslücken aufweist, ein lückenloser Schutz vor Zugriffen durch
          Dritte somit unmöglich ist.
        </p>
        <h3 class="heading-tertiary u-margin-bottom-small">Cookies </h3>
        <p class="paragraph">
          Wir verwenden auf unserer öffentlichen Seite keine von uns gesetzten
          Cookies.
        </p>
        <p class="paragraph">
          Cookies sind kleine Textdateien, die Ihr Internet-Browser auf Ihrem
          Rechner ablegt und speichert. Sie dienen dazu, unseren
          Internetauftritt und unsere Angebote zu optimieren. Es handelt sich
          dabei zumeist um sog. &#8222;Session-Cookies&#8220;, die nach dem Ende
          Ihres Besuches wieder gelöscht werden.
          <br />
          Teilweise geben diese Cookies jedoch Informationen ab, um Sie
          automatisch wieder zu erkennen. Diese Wiedererkennung erfolgt aufgrund
          der in den Cookies gespeicherten IP-Adresse. Die so erlangten
          Informationen dienen dazu, unsere Angebote zu optimieren und Ihnen
          einen leichteren Zugang auf unsere Seite zu ermöglichen.
          <br />
          Sie können die Installation der Cookies durch eine entsprechenade
          Einstellung Ihres Browsers verhindern; wir weisen Sie jedoch darauf
          hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche Funktionen
          unserer Website vollumfänglich nutzen können.
        </p>
        <h3 class="heading-tertiary u-margin-bottom-small">Serverdaten </h3>
        <p class="paragraph">
          Aus technischen Gründen werden u.a. folgende Daten, die Ihr
          Internet-Browser an uns bzw. an unseren Webspace-Provider übermittelt,
          erfasst (sogenannte Serverlogfiles):
        </p>
        <p class="paragraph">
          &#8211; Browsertyp und -version
          <br />
          &#8211; verwendetes Betriebssystem
          <br />
          &#8211; Webseite, von der aus Sie uns besuchen (Referrer URL)
          <br />
          &#8211; Webseite, die Sie besuchen
          <br />
          &#8211; Datum und Uhrzeit Ihres Zugriffs
          <br />
          &#8211; Ihre Internet Protokoll (IP)-Adresse.
        </p>
        <p class="paragraph">
          Diese anonymen Daten werden getrennt von Ihren eventuell angegebenen
          personenbezogenen Daten gespeichert und lassen so keine Rückschlüsse
          auf eine bestimmte Person zu. Sie werden zu statistischen Zwecken
          ausgewertet, um unseren Internetauftritt und unsere Angebote
          optimieren zu können.
        </p>
        <h3 class="heading-tertiary u-margin-bottom-small">
          Kontaktmöglichkeit{" "}
        </h3>
        <p class="paragraph">
          Wir bieten Ihnen auf unserer Seite die Möglichkeit, mit uns per E-Mail
          und/oder über ein Kontaktformular in Verbindung zu treten. In diesem
          Fall werden die vom Nutzer gemachten Angaben zum Zwecke der
          Bearbeitung seiner Kontaktaufnahme gespeichert. Eine Weitergabe an
          Dritte erfolgt nicht. Ein Abgleich der so erhobenen Daten mit Daten,
          die möglicherweise durch andere Komponenten unserer Seite erhoben
          werden, erfolgt ebenfalls nicht.
        </p>
        <h3 class="heading-tertiary u-margin-bottom-small">
          Google Web Fonts{" "}
        </h3>
        <p class="paragraph">
          Diese Seite nutzt zur einheitlichen Darstellung von Schriftarten so
          genannte Web Fonts, die von Google bereitgestellt werden. Beim Aufruf
          einer Seite lädt Ihr Browser die benötigten Web Fonts in ihren
          Browsercache, um Texte und Schriftarten korrekt anzuzeigen.
        </p>
        <p class="paragraph">
          Zu diesem Zweck muss der von Ihnen verwendete Browser Verbindung zu
          den Servern von Google aufnehmen. Hierdurch erlangt Google Kenntnis
          darüber, dass über Ihre IP-Adresse unsere Website aufgerufen wurde.
          Die Nutzung von Google Web Fonts erfolgt im Interesse einer
          einheitlichen und ansprechenden Darstellung unserer Online-Angebote.
          Dies stellt ein berechtigtes Interesse im Sinne von Art. 6 Abs. 1 lit.
          f DSGVO dar.
        </p>
        <p class="paragraph">
          Wenn Ihr Browser Web Fonts nicht unterstützt, wird eine
          Standardschrift von Ihrem Computer genutzt.
        </p>
        <p class="paragraph">
          Weitere Informationen zu Google Web Fonts finden Sie unter
          <a class="btn-text" href="https://developers.google.com/fonts/faq">
            {" "}
            https://developers.google.com/fonts/faq
          </a>{" "}
          und in der Datenschutzerklärung von Google:{" "}
          <a class="btn-text" href="https://www.google.com/policies/privacy/">
            https://www.google.com/policies/privacy/
          </a>
          .
        </p>
        <h3 class="heading-tertiary u-margin-bottom-small">
          Einsatz von Google-Maps mit Empfehlungskomponenten{" "}
        </h3>
        <p class="paragraph">
          Wir setzen auf unserer Seite die Komponente &#8222;Google Maps&#8220;
          in Kombination mit der sogenannten &#8222;Teilen-Funktion&#8220; ein.
          &#8222;Google Maps&#8220; ist ein Service der Firma Google Inc., 1600
          Amphitheatre Parkway, Mountain View, CA 94043 USA, nachfolgend
          „Google“.
        </p>
        <p class="paragraph">
          Bei jedem einzelnen Aufruf dieser Komponente wird von Google ein
          Cookie gesetzt, um bei der Anzeige der Seite, auf der die Komponente
          &#8222;Google Maps&#8220; integriert ist, Nutzereinstellungen und
          -daten zu verarbeiten. Dieses Cookie wird im Regelfall nicht durch das
          Schließen des Browsers gelöscht, sondern läuft nach einer bestimmten
          Zeit ab, soweit es nicht von Ihnen zuvor manuell gelöscht wird.
        </p>
        <p class="paragraph">
          Wenn Sie mit dieser Verarbeitung Ihrer Daten nicht einverstanden sind,
          so besteht die Möglichkeit, den Service von &#8222;Google Maps&#8220;
          zu deaktivieren und auf diesem Weg die Übertragung von Daten an Google
          zu verhindern. Dazu müssen Sie die Java-Script-Funktion in Ihrem
          Browser deaktivieren. Wir weisen Sie jedoch darauf hin, dass Sie in
          diesem Fall &#8222;Google Maps&#8220; nicht oder nur eingeschränkt
          nutzen können.
        </p>
        <p class="paragraph">
          Die Nutzung von &#8222;Google Maps&#8220; und der über &#8222;Google
          Maps&#8220; erlangten Informationen erfolgt gemäß den
          Google-Nutzungsbedingungen
          <br />
          <a
            class="btn-text"
            href="http://www.google.de/intl/de/policies/terms/regional.html"
            target="_blank"
            rel="nofollow noopener"
          >
            http://www.google.de/intl/de/policies/terms/regional.html
          </a>
        </p>
        <p class="paragraph">
          sowie der zusätzlichen Geschäftsbedingungen für „Google Maps“
          <br />
          <a
            class="btn-text"
            href="https://www.google.com/intl/de_de/help/terms_maps.html"
            target="_blank"
            rel="nofollow noopener"
          >
            https://www.google.com/intl/de_de/help/terms_maps.html
          </a>
        </p>

        <p class="paragraph">
          Mit dem Aufruf der &#8222;Google Maps&#8220;-Seite veranlassen diese
          „Empfehlungs-Komponenten“, dass der von Ihnen verwendete Browser eine
          entsprechende Darstellung der Komponente vom jeweiligen Anbieter
          herunterlädt. Hierdurch wird der jeweilige -vorstehend genannte-
          Anbieter darüber in Kenntnis gesetzt, welche konkrete Seite unseres
          Internetauftrittes Sie gerade besuchen.
        </p>
        <p class="paragraph">
          Sind Sie zum Zeitpunkt des Aufrufes der &#8222;Google Maps&#8220;
          Seite in Ihrem jeweiligen persönlichen Account bei den vorstehend
          genannten Anbietern eingeloggt, so können diese die so erlangten
          Informationen über die von Ihnen weiterempfohlene Webseite sowie Ihre
          IP-Adresse und andere browserbezogene Informationen erfassen und mit
          Ihrem jeweiligen Account verknüpfen.
        </p>
        <p class="paragraph">
          Wollen Sie diese Übermittlung und Speicherung von Daten über Sie und
          Ihr Verhalten auf unserer Webseite durch den jeweiligen Anbieter
          unterbinden, müssen Sie sich bei diesen Anbietern ausloggen bevor Sie
          unsere Seite besuchen.
        </p>
        <p class="paragraph">
          Näheres zu der Datenerfassung der jeweiligen Anbieter können Sie über
          die nachfolgenden Links erfahren:
        </p>
        <p class="paragraph">
          Datenschutzerklärung Twitter:{" "}
          <a
            class="btn-text"
            href="https://twitter.com/privacy?lang=de"
            target="_blank"
            rel="nofollow noopener"
          >
            https://twitter.com/privacy?lang=de
          </a>
          <br />
          Datenschutzerklärung facebook:{" "}
          <a class="btn-text" href="https://de-de.facebook.com/about/privacy">
            https://de-de.facebook.com/about/privacy
          </a>
          <br />
          Datenschutzerklärung Google+:{" "}
          <a
            class="btn-text"
            href="https://developers.google.com/+/web/buttons-policy"
            target="_blank"
            rel="nofollow noopener"
          >
            https://developers.google.com/+/web/buttons-policy
          </a>
        </p>
        <h3 class="heading-tertiary u-margin-bottom-small">
          Auskunft/Widerruf/Löschung
        </h3>
        <p class="paragraph">
          Sie können sich aufgrund des Bundesdatenschutzgesetzes bei Fragen zur
          Erhebung, Verarbeitung oder Nutzung Ihrer personenbezogenen Daten und
          deren Berichtigung, Sperrung, Löschung oder einem Widerruf einer
          erteilten Einwilligung unentgeltlich an uns wenden. Wir weisen darauf
          hin, dass Ihnen ein Recht auf Berichtigung falscher Daten oder
          Löschung personenbezogener Daten zusteht, sollte diesem Anspruch keine
          gesetzliche Aufbewahrungspflicht entgegenstehen.
          <br />
          Nehmen Sie dazu bitte per Telefon/Post oder E-Mail mit uns Kontakt
          auf.
        </p>
        <p class="paragraph">
          <a
            class="btn-text"
            href="https://www.ratgeberrecht.eu/leistungen/muster-datenschutzerklaerung.html"
            target="_blank"
            rel="noopener"
          >
            Muster-Datenschutzerklärung
          </a>{" "}
          der{" "}
          <a
            class="btn-text"
            href="https://www.ratgeberrecht.eu/"
            target="_blank"
            rel="noopener"
          >
            Anwaltskanzlei Weiß &amp; Partner
          </a>
        </p>
      </div>
    </section>
  </main>
);

export default compose()(Datenschutz);
