import React from "react";
import { set, isEmpty } from "lodash";
import { toast, ToastContainer } from "react-toastify";

import {
  compose,
  lifecycle,
  withHandlers,
  withState,
  branch,
  renderComponent,
  renderNothing
} from "recompose";
import axios from "axios";

const ChangeZeiten = ({
  setNewClosingTime,
  setNewOpeningTime,
  newOpeningTime,
  tempClosingTimes,
  submitTempTimes,
  regularWeekday,
  setTime,
  newClosingTime,
  submitRegularTimes,
  setRegularClosingTimes,
  regularClosingTimes
}) => (
  <div>
    <ToastContainer />
    <section class="section-book">
      <div class="row">
        <div class="book">
          <div class="book__form">
            <form onSubmit={submitTempTimes}>
              Heutige Öffnungszeiten
              <br />
              <input
                type="time"
                class="form__input"
                name="lastname"
                onChange={e => setNewOpeningTime(e.target.value)}
                defaultValue={
                  tempClosingTimes.status
                    ? tempClosingTimes.tempOpeningTime
                    : ""
                }
              />
              <br />
              Heutige Schließzeiten
              <br />
              <input
                class="form__input"
                type="time"
                name="Email"
                onChange={e => setNewClosingTime(e.target.value)}
                defaultValue={
                  tempClosingTimes.status
                    ? tempClosingTimes.tempClosingTime
                    : ""
                }
              />
              <br />
              <br />
              <button
                type="submit"
                class="btn btn--green"
                disabled={
                  isEmpty(newClosingTime || newOpeningTime) ? true : false
                }
              >
                Abspeichern
              </button>
            </form>
          </div>
        </div>
      </div>
    </section>
    <hr />
    <div>
      <section class="section-book">
        <div class="row">
          <div class="book">
            <div class="book__form">
              <form onSubmit={submitRegularTimes}>
                Wochentags auf
                <br />
                <input
                  class="form__input"
                  type="time"
                  name="weekday_open"
                  onChange={e => setTime(e.target.value, "weekdays.open")}
                  defaultValue={regularClosingTimes.weekdays.open}
                />
                <br />
                Wochentags zu
                <br />
                <input
                  class="form__input"
                  type="time"
                  name="weekday_closed"
                  onChange={e => setTime(e.target.value, "weekdays.closed")}
                  defaultValue={regularClosingTimes.weekdays.closed}
                />
                <br />
                <hr />
                Samstags auf
                <br />
                <input
                  type="time"
                  class="form__input"
                  name="saturday_open"
                  onChange={e => setTime(e.target.value, "saturday.open")}
                  defaultValue={regularClosingTimes.saturday.open}
                />
                <br />
                Samstags zu
                <br />
                <input
                  type="time"
                  class="form__input"
                  name="staurday_closed"
                  onChange={e => setTime(e.target.value, "saturday.closed")}
                  defaultValue={regularClosingTimes.saturday.closed}
                />
                <br />
                <hr />
                Sonntags auf
                <br />
                <input
                  type="time"
                  class="form__input"
                  name="sunday_open"
                  onChange={e => setTime(e.target.value, "sunday.open")}
                  defaultValue={regularClosingTimes.sunday.open}
                />
                <br />
                Sonntags zu
                <br />
                <input
                  type="time"
                  class="form__input"
                  name="sunday_closed"
                  onChange={e => setTime(e.target.value, "sunday.closed")}
                  defaultValue={regularClosingTimes.sunday.closed}
                />
                <br />
                <br />
                <button class="btn btn--green" type="submit">
                  Abspeichern
                </button>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
);

export default compose(
  withState("regularClosingTimes", "setRegularClosingTimes"),
  withState("newClosingTime", "setNewClosingTime"),
  withState("newOpeningTime", "setNewOpeningTime"),
  withState("regularWeekday", "setRegularWeekday"),
  withState("tempClosingTimes", "setTempClosingTimes"),
  withHandlers({
    submitTempTimes: ({
      tempClosingTimes,
      token,
      newOpeningTime,
      newClosingTime
    }) => e => {
      e.preventDefault();

      if (!newClosingTime) {
        newClosingTime = tempClosingTimes.tempClosingTime;
      }

      if (!newOpeningTime) {
        newOpeningTime = tempClosingTimes.tempOpeningTime;
      }

      const options = {
        headers: { Authorization: "Bearer " + token }
      };
      axios
        .post(
          "https://klemmer.firmen-api.com/times/tempTimes",
          {
            newOpeningTime: newOpeningTime,
            newClosingTime: newClosingTime
          },
          options
        )
        .then(result => {
          toast.success(
            "Die  temporären Öffnungszeiten wurden erfolgreich gespeichert, und heute Nacht zurückgesetzt",
            {
              position: toast.POSITION.TOP_CENTER
            }
          );
          //return;
        })
        .catch(err => {
          toast.error(
            "Neue Temp Öffnungszeiten konnten nicht gespeichert werden",
            {
              position: toast.POSITION.TOP_CENTER
            }
          );
        });
    },
    setTime: ({ regularClosingTimes, setRegularClosingTimes }) => (
      value,
      path
    ) => {
      set(regularClosingTimes, path, value);

      setRegularClosingTimes(regularClosingTimes);
    },
    submitRegularTimes: ({ regularClosingTimes, token }) => e => {
      e.preventDefault();
      const options = {
        headers: { Authorization: "Bearer " + token }
      };

      axios
        .post(
          "https://klemmer.firmen-api.com/times/regularTimes",
          {
            regularClosingTimes
          },
          options
        )
        .then(result => {
          toast.success("Die Öffnungszeiten wurden erfolgreich gespeichert", {
            position: toast.POSITION.TOP_CENTER
          });
        })
        .catch(err => {
          toast.error("Neue Öffnungszeiten konnten nicht gespeichert werden", {
            position: toast.POSITION.TOP_CENTER
          });
        });
    }
  }),
  lifecycle({
    componentDidMount() {
      axios
        .get("https://klemmer.firmen-api.com/times/regularTimes")
        .then(result => {
          this.props.setRegularClosingTimes(result.data);
        })
        .catch(err => {});

      axios
        .get("https://klemmer.firmen-api.com/times/tempTimes")
        .then(result => {
          this.props.setTempClosingTimes(result.data);
        })
        .catch(err => {});
    }
  }),
  branch(
    ({ tempClosingTimes, regularClosingTimes }) =>
      tempClosingTimes && regularClosingTimes,
    renderComponent(ChangeZeiten),
    renderNothing
  )
)(ChangeZeiten);
