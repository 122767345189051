import React from "react";

import "./sass/main.scss";
import "react-image-gallery/styles/scss/image-gallery.scss";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Zeiten from "./components/zeiten";
import Datenschutz from "./components/main/datenschutz,";
import Impressum from "./components/main/impressum";

import Main from "./components/main";
function App() {
  return (
    <Router>
      <Switch>
        <Route path="/zeiten">
          <Zeiten />
        </Route>

        <Route path="/datenschutz">
          <Datenschutz />
        </Route>

        <Route path="/impressum">
          <Impressum />
        </Route>

        <Route path="/">
          <Main />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
