import React from "react";
import { compose, branch, renderNothing, renderComponent } from "recompose";

const OpeningTimes = ({ openHours }) => {
  var options = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  const closedUntil = new Intl.DateTimeFormat("de-DE", options).format(
    new Date(openHours.regularClosingTimes.closed)
  );

  return (
    <p class="header__info-box-text">
      {new Date(openHours.regularClosingTimes.closed) > new Date()
        ? `Aktuell haben wir Sommerpause. Wir sind ab dem ${closedUntil} wieder für Sie da.`
        : `Wir sind heute von ${openHours.regularClosingTimes.today.open} bis ${openHours.regularClosingTimes.today.closed} Uhr für Sie da.`}
      {/* Aufgrund der aktuellen Lage sind zur Zeit nur Abholservice und kostenlose
    Lieferung möglich. Rufen Sie uns unter{" "}
    <a href="tel:+491716954278" class="black-color">
      0171/6954278
    </a>{" "}
    oder{" "}
    <a href="tel:+49228669094" class="black-color">
      0228/669094
    </a>{" "}
    an. */}
    </p>
  );
};
const OpeningTimesTemp = () => (
  <p class="header__info-box-text">
    <div class="loader">Loading...</div>
  </p>
);

export default compose(
  branch(
    ({ openHours }) =>
      openHours.tempClosingTimes && openHours.regularClosingTimes,
    renderComponent(OpeningTimes),
    renderComponent(OpeningTimesTemp)
  )
)(OpeningTimes);
